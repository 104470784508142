
import Vue from "vue";
import { HTTP } from "@/utils/http-common";
export default Vue.extend({
  data(): any {
    return {
      showLoadingScreen: true,
      companies: {
        data: [],
      },
      newMessage: false,
      editingModel: {
        name: "",
      },
    };
  },
  methods: {
    addMessage() {
      this.newMessage = "add";
    },
    refreshData() {
      HTTP.post('api/webhook/render').then(() => {
          alert('Żądanie zostało przesłane. Prosimy o cierpliwość, odświeżanie danych może zająć do 5 minut.');
      }).catch(() => {
          alert('Wystąpił błąd podczas odświeżania danych. Prosimy spróbować ponownie później.');
        })
    }
  },
  created() {
    HTTP.get(`api/companies/list`)
      .then((response) => {
        response.data.data.map((company: any) => {
          this.companies.data.push({
            id: company.id,
            name: company.name,
            editions: company.packages.map((singlePackage: any) => {
              return singlePackage.name;
            }),
            telephone: company.phone,
          });
        });
      })
      .then(() => {
        this.showLoadingScreen = false;
      });
  },
});

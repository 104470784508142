var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.showLoadingScreen)?_c('div',{staticClass:"loadingScreen"},[_vm._v(" Ładowanie listy firm… ")]):_vm._e(),(!_vm.showLoadingScreen)?_c('section',{staticClass:"columns"},[_c('button',{staticClass:"btn",on:{"click":_vm.addMessage}},[_vm._v("Dodaj firmę")]),_c('button',{staticClass:"btn",staticStyle:{"margin-left":"15px"},on:{"click":_vm.refreshData}},[_vm._v("Odśwież infokioski")]),(_vm.newMessage)?_c('edition-form',{attrs:{"newMessage":_vm.newMessage,"editingModel":_vm.editingModel,"requestUrl":'api/company',"companiesList":_vm.companies.data,"formLayout":[
        {
          formField: 'input',
          name: 'Nazwa',
          placeholder: 'Nazwa firmy',
          model: 'name',
        },
      ]}}):_vm._e(),_c('table-data',{attrs:{"headers":['ID', 'Nazwa'],"tableData":this.companies.data,"adminCategory":'company',"searchItem":'name'}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }